import './QuantitySelector.css';
import { useState } from 'react';
import NumPad from 'react-numpad';
import {Grid, TextField} from '@material-ui/core';



function QuantitySelector(props) {

  let [intValue, setIntValue] = useState( 0 );

  let numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  let updateQuantity = (quantity) => {
    setIntValue(quantity);
    props.quantitySelectedProp({quantity: quantity});
  }

  let clearButtonAction = () => {
    updateQuantity(0);
  }

  return (
    <div className="QuantitySelector">
        {/*}
        <label className="search-label">
            <input type="text" pattern="[0-9]*"  name="text" className="input" required="" placeholder="# of material..."/>
        </label>
        <button className="addButton">
            <svg viewBox="0 0 24 24"><title>plus-minus-variant</title><path fill="#fff" d="M3 7H6V4H8V7H11V9H8V12H6V9H3V7M13 15H21V17H13V15M16.04 3H18.35L7.96 21H5.65L16.04 3Z" /></svg>
        </button>
        */}
        
        <button className="clearButton" onClick={clearButtonAction}>x</button>
        <pre>{numberWithCommas(intValue)}</pre>
        <NumPad.Number
            onChange={value => updateQuantity(parseInt(value)+intValue)}
            decimal={false}
            placeholder="Integer number"
        >
            <button className="plusButton">+</button>
        </NumPad.Number>
    </div>
  );
}

export default QuantitySelector;