import MatComponent from './MatComponent';
import './MatContainer.css';
import { useState } from 'react';

function MatContainer(props) {

    let [pricesObject, setPricesObject] = useState({
        scrapPrice: 0,
        ironPrice: 0,
        steelPrice: 0,
        aluminumPrice: 0,
        copperPrice: 0,
        rubberPrice: 0,
        glassPrice: 0,
        plasticPrice: 0
    });

    let [totalPrice, setTotalPrice] = useState(0);
    let recomputeTotalPrice = (newObj) => {
        var newTotal = 
            newObj.scrapPrice + newObj.ironPrice +
            newObj.steelPrice + newObj.aluminumPrice +
            newObj.copperPrice + newObj.rubberPrice +
            newObj.glassPrice + newObj.plasticPrice;

        console.log(newObj.scrapPrice);
        setTotalPrice(newTotal);
        props.totalPrice(newTotal);
    }


    let scrapPriceCallback = (price) => {
        var newObj = {
            scrapPrice: price.price,
            ironPrice: pricesObject.ironPrice,
            steelPrice: pricesObject.steelPrice,
            aluminumPrice: pricesObject.aluminumPrice,
            copperPrice: pricesObject.copperPrice,
            rubberPrice: pricesObject.rubberPrice,
            glassPrice: pricesObject.glassPrice,
            plasticPrice: pricesObject.plasticPrice
        };
        setPricesObject(newObj);
        recomputeTotalPrice(newObj);
    }

    let ironPriceCallback = (price) => {
        var newObj = {
            scrapPrice: pricesObject.scrapPrice,
            ironPrice: price.price,
            steelPrice: pricesObject.steelPrice,
            aluminumPrice: pricesObject.aluminumPrice,
            copperPrice: pricesObject.copperPrice,
            rubberPrice: pricesObject.rubberPrice,
            glassPrice: pricesObject.glassPrice,
            plasticPrice: pricesObject.plasticPrice
        };
        setPricesObject(newObj);
        recomputeTotalPrice(newObj);
    }

    let steelPriceCallback = (price) => {
        var newObj = {
            scrapPrice: pricesObject.scrapPrice,
            ironPrice: pricesObject.ironPrice,
            steelPrice: price.price,
            aluminumPrice: pricesObject.aluminumPrice,
            copperPrice: pricesObject.copperPrice,
            rubberPrice: pricesObject.rubberPrice,
            glassPrice: pricesObject.glassPrice,
            plasticPrice: pricesObject.plasticPrice
        };
        setPricesObject(newObj);
        recomputeTotalPrice(newObj);
    }

    let aluminumPriceCallback = (price) => {
        var newObj = {
            scrapPrice: pricesObject.scrapPrice,
            ironPrice: pricesObject.ironPrice,
            steelPrice: pricesObject.steelPrice,
            aluminumPrice: price.price,
            copperPrice: pricesObject.copperPrice,
            rubberPrice: pricesObject.rubberPrice,
            glassPrice: pricesObject.glassPrice,
            plasticPrice: pricesObject.plasticPrice
        };
        setPricesObject(newObj);
        recomputeTotalPrice(newObj);
    }

    let copperPriceCallback = (price) => {
        var newObj = {
            scrapPrice: pricesObject.scrapPrice,
            ironPrice: pricesObject.ironPrice,
            steelPrice: pricesObject.steelPrice,
            aluminumPrice: pricesObject.aluminumPrice,
            copperPrice: price.price,
            rubberPrice: pricesObject.rubberPrice,
            glassPrice: pricesObject.glassPrice,
            plasticPrice: pricesObject.plasticPrice
        };
        setPricesObject(newObj);
        recomputeTotalPrice(newObj);
    }

    let rubberPriceCallback = (price) => {
        var newObj = {
            scrapPrice: pricesObject.scrapPrice,
            ironPrice: pricesObject.ironPrice,
            steelPrice: pricesObject.steelPrice,
            aluminumPrice: pricesObject.aluminumPrice,
            copperPrice: pricesObject.copperPrice,
            rubberPrice: price.price,
            glassPrice: pricesObject.glassPrice,
            plasticPrice: pricesObject.plasticPrice
        };
        setPricesObject(newObj);
        recomputeTotalPrice(newObj);
    }

    let glassPriceCallback = (price) => {
        var newObj = {
            scrapPrice: pricesObject.scrapPrice,
            ironPrice: pricesObject.ironPrice,
            steelPrice: pricesObject.steelPrice,
            aluminumPrice: pricesObject.aluminumPrice,
            copperPrice: pricesObject.copperPrice,
            rubberPrice: pricesObject.rubberPrice,
            glassPrice: price.price,
            plasticPrice: pricesObject.plasticPrice
        };
        setPricesObject(newObj);
        recomputeTotalPrice(newObj);
    }

    let plasticPriceCallback = (price) => {
        var newObj = {
            scrapPrice: pricesObject.scrapPrice,
            ironPrice: pricesObject.ironPrice,
            steelPrice: pricesObject.steelPrice,
            aluminumPrice: pricesObject.aluminumPrice,
            copperPrice: pricesObject.copperPrice,
            rubberPrice: pricesObject.rubberPrice,
            glassPrice: pricesObject.glassPrice,
            plasticPrice: price.price
        };
        setPricesObject(newObj);
        recomputeTotalPrice(newObj);
    }

    let numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

  return (
    <div>
        <div className="spacer"/>
        <div className="MatContainer">
            <div className="col">
                <MatComponent priceCallbackProp={scrapPriceCallback} material="scrap"/>
                <MatComponent priceCallbackProp={ironPriceCallback} material="iron"/>
                <MatComponent priceCallbackProp={steelPriceCallback} material="steel"/>
                <MatComponent priceCallbackProp={aluminumPriceCallback} material="aluminum"/>
            </div>
            <div className="col">
                <MatComponent priceCallbackProp={copperPriceCallback} material="copper"/>
                <MatComponent priceCallbackProp={rubberPriceCallback} material="rubber"/>
                <MatComponent priceCallbackProp={glassPriceCallback} material="glass"/>
                <MatComponent priceCallbackProp={plasticPriceCallback} material="plastic"/>
            </div>
        </div>
        <div className="spacer"/>
    </div>
  );
}

export default MatContainer;