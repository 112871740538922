import './MatComponent.css';
import PriceSelector from './PriceSelector/PriceSelector';
import QuantitySelector from './QuantitySelector/QuantitySelector';
import { useState } from 'react';
import scrapImg from '../images/scrap.png';
import ironImg from '../images/iron.png';
import steelImg from '../images/steel.png';
import aluminumImg from '../images/aluminum.png';
import copperImg from '../images/copper.png';
import rubberImg from '../images/rubber.png';
import plasticImg from '../images/plastic.png';
import glassImg from '../images/glass.png';

function MatComponent(props) {

  let [matPrice, setMatPrice] = useState({price: 0});
  let [matQuantity, setMatQuantity] = useState({quantity: 0});
  let [totalPrice, setTotalPrice] = useState(0);

  let priceSelected = (priceObj) => {
    setMatPrice(priceObj);
    updatePrice(priceObj.price, matQuantity.quantity);
    console.log("Parent recieved price prop: "+priceObj.price);
  }

  let quantitySelected = (quantityObj) => {
    setMatQuantity(quantityObj);
    updatePrice(matPrice.price, quantityObj.quantity);
    console.log("Parent recieved quantity prop: "+quantityObj.quantity);
  }

  let updatePrice = (matP, matQ) => {
    setTotalPrice(matP * matQ);
    props.priceCallbackProp({price: (matP * matQ)});
  }



  let numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  let getImage = () => {
    if(props.material == 'scrap')
      return scrapImg;
    if(props.material == 'iron')
      return ironImg;
    if(props.material == 'steel')
      return steelImg;
    if(props.material == 'aluminum')
      return aluminumImg;
    if(props.material == 'copper')
      return copperImg;
    if(props.material == 'rubber')
      return rubberImg;
    if(props.material == 'glass')
      return glassImg;
    if(props.material == 'plastic')
      return plasticImg;
  }

  return (
    <div className="MatComponent">
      <div className="matIcon">
        <img src={getImage()} className="matImg"/>
        <h5>{props.material}</h5>
      </div>
      <PriceSelector priceSelectedProp={priceSelected}/>
      <QuantitySelector quantitySelectedProp={quantitySelected}/>
      <h1>${numberWithCommas(totalPrice)}</h1>
    </div>
  );
}

export default MatComponent;