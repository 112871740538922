import logo from './logo.png';
import './App.css';
import MatContainer from './Components/MatContainer';
import Keypad from './Components/Keypad/Keypad';
import zIndex from '@material-ui/core/styles/zIndex';
import { useState } from 'react';


const headerStyle = {
  background: "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.2))",
  fontSize: "20px",
  color: "white",
  borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
  textAlign: "center",
  position: "fixed",
  left: "0",
  top: "0",
  height: "80px",
  width: "100vw",
  zIndex: "100",
  backdropFilter: "blur(10px)",
  WebkitBackdropFilter: "blur(10px)"
};

const phantomHStyle = {
  display: "block",
  position: "fixed",
  left: "0",
  height: "80px",
  width: "100vw"
};

function Header({ children }) {
  return (
    <div>
      <div style={headerStyle}>{children}</div>
      <div style={phantomHStyle} />
    </div>
  );
}

const footerStyle = {
  background: "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.2))",
  fontSize: "20px",
  color: "white",
  borderTop: "1px solid rgba(255, 255, 255, 0.1)",
  textAlign: "center",
  position: "fixed",
  left: "0",
  bottom: "0",
  height: "100px",
  width: "100vw",
  zIndex: "100",
  backdropFilter: "blur(10px)",
  WebkitBackdropFilter: "blur(10px)"
};

const phantomStyle = {
  display: "block",
  position: "fixed",
  left: "0",
  height: "100px",
  width: "100vw"
};

function Footer({ children }) {
  return (
    <div>
      <div style={phantomStyle} />
      <div style={footerStyle}>{children}</div>
    </div>
  );
}


function App() {

  let numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  let [totalPrice, setTotalPrice] = useState(0);
  let totalPriceUpdated = (price) => {
    setTotalPrice(price);
  }

  return (
    <div className="App">
      <Header>
        <img src={logo}/>
      </Header>
      <MatContainer totalPrice={totalPriceUpdated}/>
      <Footer>
        <h1>${numberWithCommas(totalPrice)}</h1>
      </Footer>
    </div>
  );
}

export default App;
