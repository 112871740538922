import './Keypad.css';
import { useState } from 'react';

function Keypad() {

  return (
    <div className="KeypadContainer">
      <div className="Keypad">
        
      </div>
    </div>
  );
}

export default Keypad;