import './PriceSelector.css';
import { useState } from 'react';





function PriceSelector(props) {

    let [startMaskPercentage, setStartMaskPercentage] = useState(-5);
    let [translateXPercentage, setTranslateXPercentage] = useState(5);
    let [position, setPosition] = useState(0);
    let [selectedPrice, setSelectedPrice] = useState(0);
    let amountToMove = 9;

    function scrollForwards(){
        var newPosition = position + 1;
        if(newPosition > 10)
            return;

        setStartMaskPercentage(startMaskPercentage + amountToMove);
        setTranslateXPercentage(translateXPercentage - amountToMove);
        setPosition(newPosition);

        var newPrice = newPosition * 5;
        setSelectedPrice(newPrice);
        props.priceSelectedProp({price: newPrice});
    }

    function scrollBackwards(){
        var newPosition = position - 1;
        if(newPosition < 0)
            return;

        setStartMaskPercentage(startMaskPercentage - amountToMove);
        setTranslateXPercentage(translateXPercentage + amountToMove);
        setPosition(newPosition);
        
        var newPrice = newPosition * 5;
        setSelectedPrice(newPrice);
        props.priceSelectedProp({price: newPrice});
    }

    return (
        <div className="PriceSelector">
            <button onClick={scrollBackwards}>{'<'}</button>
            <div id="wheelContainer">
                <pre id="priceWheel" style={{
                        transform: 'translate('+translateXPercentage+'%, -60%)',
                        mask: 'linear-gradient(to right, transparent '+startMaskPercentage+'%, black '+(startMaskPercentage+8)+'%, black '+(startMaskPercentage+14)+'%, transparent '+(startMaskPercentage+20)+'%)',
                        WebkitMask: 'linear-gradient(to right, transparent '+startMaskPercentage+'%, black '+(startMaskPercentage+8)+'%, black '+(startMaskPercentage+14)+'%, transparent '+(startMaskPercentage+20)+'%)',
                    }} className='.wheel'>  $0   $5  $10  $15  $20  $25  $30  $35  $40  $45  $50</pre>
            </div>
            <button onClick={scrollForwards}>{'>'}</button>
        </div>
    );
}

export default PriceSelector;